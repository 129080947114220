header {
  z-index: 999;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
}
header {
  background-color: #000000cc;
  padding: 10px 20px;
  height: 120px;
  border-bottom: solid 2px #ffffffcc;
}
header > h1,
header > p {
  display: none;
}
#menuToggle {
  display: block;
  position: relative;
  top: 40px;
  margin-right: 20px;
  left: 50px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  width: fit-content;
}
.ant-menu-submenu .ant-menu-vertical {
  background-color: #000000;
  border: solid #6a757d 2px;
  border-radius: 4px;
}
#menuToggle a {
  text-decoration: none;
  color: #fff;
  transition: color 0.3s ease;
}
#menuToggle li.active {
  color: #f9e54c;
}
#menuToggle a:hover {
  color: #f9e54c;
}
.slick-dots li button:before {
  color: #fff;
  opacity: 1;
}
.slick-dots li.slick-active button:before {
  color: #f9e54c;
  opacity: 1;
}
#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
  -webkit-touch-callout: none;
}

#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #fff;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #fff;
}
#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}
#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}
#menu {
  position: absolute;
  width: 400px;
  height: calc(100vh + 29px);
  margin: -100px 0 0 -94px;
  padding-left: 94px;
  padding-top: 125px;
  padding-right: 20px;
  background-color: #000000ee;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  border-right: solid #f9e54c;
}
#menu li {
  padding: 2px 0;
  font-size: 18px;
  font-weight: bold;
}

#menuToggle input:checked ~ ul {
  transform: none;
}
header > nav {
  height: 0;
}
#menu-bottom {
  position: absolute;
  bottom: 20px;
}
#menu-form {
  width: calc(100% - 20px) !important;
}
#menu-form * {
  width: 100% !important;
}
#menu-form input {
  position: relative;
  background-color: white;
  margin-bottom: 12px;
  opacity: 1;
  border-radius: 0px;
  top: 0px;
  left: 0px;
}
#menu-form p:first-child {
  border-bottom: solid white;
}
#tsparticles canvas {
  position: relative;
}
@media (max-width: 768px) {
  header > div div:last-child ul {
    display: none;
  }
}
